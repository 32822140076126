import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, navigate } from 'gatsby'

// Images
import Pin from 'img/pin.inline.svg'

// Components
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import { motion } from 'framer-motion'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkPosts,
} from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'
import useMedia from 'use-media'

interface ProjectProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Projects: React.FC<ProjectProps> = ({ fields }) => {
  const {
    allWpProject,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.projectsQueryQuery>(graphql`
    query projectsQuery {
      allWpProject(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalProjectFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpProject.edges

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="projects"
        limit={Number(fields.limit)}
      >
        <div>
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row gy-lg-5 gy-2 gx-lg-3 gx-2">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-lg-4 col-sm-6">
              <BlogGridPost node={node} blogFields={fields} />
            </div>
          )
        })}
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          {/* @ts-ignore */}
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpProject
}

const StyledBlogGridPost = styled(motion.div)`
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: 575px) {
    height: 230px;
    border-radius: 10px;
  }
`

const Content = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 0, 200px);
  z-index: 4 !important;
  padding: 35px;

  & p {
    color: ${({ theme }) => theme.color.light};
    font-size: 20px;
    line-height: 20px;
    padding-bottom: 0.5rem;
  }

  @media screen and (max-width: 575px) {
    padding: 15px;

    & p {
      margin-bottom: 0 !important;
    }
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  position: relative;
  z-index: 1 !important;
  border-radius: 15px;
  aspect-ratio: 16/9;

  & img {
    border-radius: 15px;
  }

  @media (max-width: 575px) {
    height: 100%;
    border-radius: 10px;

    & img {
      border-radius: 10px;
      object-position: 50% 50%;
    }
  }
`

const Gradient = styled.div`
  position: absolute;
  height: 135px;
  width: 100%;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 0, 150px);
  z-index: 2;
  background: linear-gradient(180deg, rgba(28, 26, 51, 0) 0%, #1c1a33 76.07%);
  border-radius: 15px;

  @media (max-width: 575px) {
    border-radius: 0 0 10px 10px;
  }
`

const Solid = styled(motion.div)`
  position: absolute;
  transform: translate3d(0, 0, 175px);
  z-index: 3;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.tertiary};
  border-radius: 0 0 15px 15px;

  @media (max-width: 575px) {
    border-radius: 0 0 10px 10px;
  }
`

const Location = styled.div`
  & div {
    color: ${({ theme }) => theme.color.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-transform: uppercase;
    font-size: 14px;

    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const mobile = useMedia({ maxWidth: 575 })

  return (
    <StyledBlogGridPost
      initial="init"
      whileHover="hover"
      onClick={() => navigate(node.uri || '/')}
    >
      <StyledPlaatjie loading="eager" image={node.projectrecap?.image} alt="" />
      <Gradient />
      <Solid
        variants={{
          hover: {
            height: mobile ? 80 : 110,
          },
          init: {
            height: 0,
          },
        }}
      />
      <Content>
        <ParseContent content={node.projectrecap?.excerpt || ''} />
        {node.projectrecap?.location && (
          <Location className="d-flex align-items-center">
            <Pin />
            <div className="ms-1">{node.projectrecap.location}</div>
          </Location>
        )}
      </Content>
    </StyledBlogGridPost>
  )
}

export default Projects
